<template>
  <TourTooltip
    :has-previous-step="false"
    :steps="steps"
    @next="nextStep($event)"
    @skip="$emit('skip')"
  />
</template>

<script>
import TourTooltip from '@/components/TourTooltip'

export default {
  name: 'TourAssessments',

  components: {
    TourTooltip,
  },

  props: {
    application: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      steps: [
        {
          target: '#v-step-0',
          content: `
            <h4 class="v-step__title">Oi, que tal um tour pelo produto?</h4>
            <span class="v-step__body">
              Esta é página de Avaliações. Escolha uma avaliação para acessar suas provas. Vamos levá-lo até lá!
            </span>`,
          params: {
            placement: 'right',
          },
        },
      ],
    }
  },

  methods: {
    nextStep() {
      this.$router.push({
        name: 'assessmentExams',
        params: {
          assessmentId: this.application.assessmentId,
          applicationId: this.application.id,
        },
      })
      this.$emit('next')
    },
  },
}
</script>
